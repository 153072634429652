import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDoctors(ctx, data) {
      return useJwt.getDoctors(data).then(response => response);
    },

    getDoctorDetail(ctx, id) {
      return useJwt.getDoctorDetail(id).then(response => response);
    },
    createDoctor(ctx, data) {
      return useJwt.createDoctor(data).then(response => response);
    },
    updateDoctor(ctx, id, data) {
      return useJwt.updateDoctor(id, data).then(response => response);
    },
    downloadImportTemplate(ctx, data) {
      return useJwt.getDoctorImportTemplateFile(data).then(response => response);
    },
    importDoctors(ctx, data) {
      return useJwt.importDoctors(data).then(response => response);
    },
    exportDoctors(ctx, data) {
      return useJwt.exportApartments(data).then(response => response);
    },
    deleteDoctor(ctx, doctorId) {
      return useJwt.deleteDoctor(doctorId).then(response => response);
    },
  },
};
